import { Injectable } from '@angular/core';
import * as enums from './enum-constants';
import { DenBaseEnumApi } from '../../base-apis/den-base-enum-api';
import { BaseEnumType } from 'libs/dc-core/models/base.models';

@Injectable()
export class NodeConfigOptionsApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.NodeConfigOptionsConstants.one_vcpu, 'one_vcpu'),
 	 	new BaseEnumType(enums.NodeConfigOptionsConstants.two_vcpu, 'two_vcpu'),
 	 	new BaseEnumType(enums.NodeConfigOptionsConstants.two_vcpu_eight_gb_expandible, 'two_vcpu_eight_gb_expandible'),
 	 	new BaseEnumType(enums.NodeConfigOptionsConstants.two_vcpu_four_gb_expandible, 'two_vcpu_four_gb_expandible')
        ];
    }
}

@Injectable()
export class PaymentFrequencyEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.PaymentFrequencyEnumConstants.monthly, 'monthly'),
 	 	new BaseEnumType(enums.PaymentFrequencyEnumConstants.six_months, 'six_months'),
 	 	new BaseEnumType(enums.PaymentFrequencyEnumConstants.three_months, 'three_months'),
 	 	new BaseEnumType(enums.PaymentFrequencyEnumConstants.yearly, 'yearly')
        ];
    }
}

@Injectable()
export class DeploymentTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.DeploymentTypeConstants.aws_cloud, 'aws_cloud'),
 	 	new BaseEnumType(enums.DeploymentTypeConstants.azure_cloud, 'azure_cloud'),
 	 	new BaseEnumType(enums.DeploymentTypeConstants.devum_cloud, 'devum_cloud'),
 	 	new BaseEnumType(enums.DeploymentTypeConstants.docker, 'docker'),
 	 	new BaseEnumType(enums.DeploymentTypeConstants.google_cloud, 'google_cloud'),
 	 	new BaseEnumType(enums.DeploymentTypeConstants.private_cloud, 'private_cloud')
        ];
    }
}

@Injectable()
export class ResourceEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.ResourceEnumConstants.Kafka, 'Kafka'),
 	 	new BaseEnumType(enums.ResourceEnumConstants.LoadBalancer, 'LoadBalancer'),
 	 	new BaseEnumType(enums.ResourceEnumConstants.MongoDB, 'MongoDB'),
 	 	new BaseEnumType(enums.ResourceEnumConstants.RDS, 'RDS'),
 	 	new BaseEnumType(enums.ResourceEnumConstants.Redis, 'Redis')
        ];
    }
}

@Injectable()
export class CustomerProfileUiEntitiesEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.CustomerProfileUiEntitiesEnumConstants.CustomerProfileUI, 'CustomerProfileUI'),
 	 	new BaseEnumType(enums.CustomerProfileUiEntitiesEnumConstants.InitialStateUI, 'InitialStateUI'),
 	 	new BaseEnumType(enums.CustomerProfileUiEntitiesEnumConstants.NoProfileUI, 'NoProfileUI')
        ];
    }
}

@Injectable()
export class BillingPeriodEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.BillingPeriodEnumConstants.MSDeploymentsUI, 'MSDeploymentsUI'),
 	 	new BaseEnumType(enums.BillingPeriodEnumConstants.MSInitialDENDeploymentUI, 'MSInitialDENDeploymentUI'),
 	 	new BaseEnumType(enums.BillingPeriodEnumConstants.month, 'month'),
 	 	new BaseEnumType(enums.BillingPeriodEnumConstants.year, 'year')
        ];
    }
}

@Injectable()
export class ServiceStatusEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.ServiceStatusEnumConstants.active, 'active'),
 	 	new BaseEnumType(enums.ServiceStatusEnumConstants.inactive, 'inactive'),
 	 	new BaseEnumType(enums.ServiceStatusEnumConstants.inprogress, 'inprogress')
        ];
    }
}

@Injectable()
export class DevumPlanEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.DevumPlanEnumConstants.DevumHosted, 'DevumHosted'),
 	 	new BaseEnumType(enums.DevumPlanEnumConstants.Enterprise, 'Enterprise'),
 	 	new BaseEnumType(enums.DevumPlanEnumConstants.Free, 'Free'),
 	 	new BaseEnumType(enums.DevumPlanEnumConstants.Premium, 'Premium'),
 	 	new BaseEnumType(enums.DevumPlanEnumConstants.Professional, 'Professional')
        ];
    }
}

@Injectable()
export class MemoryTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.MemoryTypeConstants.Gi, 'Gi'),
 	 	new BaseEnumType(enums.MemoryTypeConstants.Mi, 'Mi')
        ];
    }
}

@Injectable()
export class ActionEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.ActionEnumConstants.restart, 'restart'),
 	 	new BaseEnumType(enums.ActionEnumConstants.start, 'start'),
 	 	new BaseEnumType(enums.ActionEnumConstants.stop, 'stop')
        ];
    }
}

@Injectable()
export class CurrencyTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.CurrencyTypeEnumConstants.inr, 'inr'),
 	 	new BaseEnumType(enums.CurrencyTypeEnumConstants.usd, 'usd')
        ];
    }
}

@Injectable()
export class EnvironmentTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.EnvironmentTypeConstants.dev, 'dev'),
 	 	new BaseEnumType(enums.EnvironmentTypeConstants.prod, 'prod'),
 	 	new BaseEnumType(enums.EnvironmentTypeConstants.qa, 'qa'),
 	 	new BaseEnumType(enums.EnvironmentTypeConstants.uat, 'uat')
        ];
    }
}

@Injectable()
export class LeadSourceEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.LeadSourceEnumConstants.facebook, 'facebook'),
 	 	new BaseEnumType(enums.LeadSourceEnumConstants.google, 'google'),
 	 	new BaseEnumType(enums.LeadSourceEnumConstants.other, 'other'),
 	 	new BaseEnumType(enums.LeadSourceEnumConstants.salesteam, 'salesteam')
        ];
    }
}

@Injectable()
export class PaymentMethodEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.PaymentMethodEnumConstants.credit_card, 'credit_card'),
 	 	new BaseEnumType(enums.PaymentMethodEnumConstants.debit_card, 'debit_card'),
 	 	new BaseEnumType(enums.PaymentMethodEnumConstants.netbanking, 'netbanking')
        ];
    }
}

@Injectable()
export class NumberOfNodesApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.NumberOfNodesConstants.eight, 'eight'),
 	 	new BaseEnumType(enums.NumberOfNodesConstants.five, 'five'),
 	 	new BaseEnumType(enums.NumberOfNodesConstants.four, 'four'),
 	 	new BaseEnumType(enums.NumberOfNodesConstants.nine, 'nine'),
 	 	new BaseEnumType(enums.NumberOfNodesConstants.one, 'one'),
 	 	new BaseEnumType(enums.NumberOfNodesConstants.seven, 'seven'),
 	 	new BaseEnumType(enums.NumberOfNodesConstants.shared, 'shared'),
 	 	new BaseEnumType(enums.NumberOfNodesConstants.six, 'six'),
 	 	new BaseEnumType(enums.NumberOfNodesConstants.ten, 'ten'),
 	 	new BaseEnumType(enums.NumberOfNodesConstants.three, 'three'),
 	 	new BaseEnumType(enums.NumberOfNodesConstants.two, 'two')
        ];
    }
}

@Injectable()
export class ServiceTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.ServiceTypeConstants.core, 'core'),
 	 	new BaseEnumType(enums.ServiceTypeConstants.custom, 'custom')
        ];
    }
}

@Injectable()
export class DeploymentUIEntitiesEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.DeploymentUIEntitiesEnumConstants.BEDeploymentUI, 'BEDeploymentUI'),
 	 	new BaseEnumType(enums.DeploymentUIEntitiesEnumConstants.DenDeploymentsUI, 'DenDeploymentsUI'),
 	 	new BaseEnumType(enums.DeploymentUIEntitiesEnumConstants.InitialDENDeploymentUI, 'InitialDENDeploymentUI')
        ];
    }
}

@Injectable()
export class MicroServiceOptionsApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.MicroServiceOptionsConstants.basic, 'basic'),
 	 	new BaseEnumType(enums.MicroServiceOptionsConstants.premium, 'premium')
        ];
    }
}

@Injectable()
export class CPUTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.CPUTypeConstants.m, 'm'),
 	 	new BaseEnumType(enums.CPUTypeConstants.vCPU, 'vCPU')
        ];
    }
}

@Injectable()
export class PeriodOptionsApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.PeriodOptionsConstants.five_minutes, 'five_minutes'),
 	 	new BaseEnumType(enums.PeriodOptionsConstants.five_seconds, 'five_seconds'),
 	 	new BaseEnumType(enums.PeriodOptionsConstants.no_limit, 'no limit'),
 	 	new BaseEnumType(enums.PeriodOptionsConstants.one_minute, 'one_minute'),
 	 	new BaseEnumType(enums.PeriodOptionsConstants.thirty_seconds, 'thirty_seconds'),
 	 	new BaseEnumType(enums.PeriodOptionsConstants.two_minutes, 'two_minutes')
        ];
    }
}

@Injectable()
export class DevumTimeUnitTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.DevumTimeUnitTypeConstants.days, 'days'),
 	 	new BaseEnumType(enums.DevumTimeUnitTypeConstants.hours, 'hours'),
 	 	new BaseEnumType(enums.DevumTimeUnitTypeConstants.milliseconds, 'milliseconds'),
 	 	new BaseEnumType(enums.DevumTimeUnitTypeConstants.minutes, 'minutes'),
 	 	new BaseEnumType(enums.DevumTimeUnitTypeConstants.month, 'month'),
 	 	new BaseEnumType(enums.DevumTimeUnitTypeConstants.seconds, 'seconds'),
 	 	new BaseEnumType(enums.DevumTimeUnitTypeConstants.week, 'week')
        ];
    }
}

@Injectable()
export class KubernetesServiceTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    setDataset(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.KubernetesServiceTypeConstants.ClusterIP, 'ClusterIP'),
 	 	new BaseEnumType(enums.KubernetesServiceTypeConstants.LoadBalancer, 'LoadBalancer'),
 	 	new BaseEnumType(enums.KubernetesServiceTypeConstants.NodePort, 'NodePort')
        ];
    }
}
